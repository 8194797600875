import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'place/:uname',
    loadChildren: () => import('./place/place.module').then( m => m.PlacePageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'checkout/success',
    loadChildren: () => import('./checkout/success/success.module').then( m => m.SuccessPageModule)
  },
  {
    path: 'checkout/cancel',
    loadChildren: () => import('./checkout/cancel/cancel.module').then( m => m.CancelPageModule)
  },
  {
    path: 'map-modal',
    loadChildren: () => import('./map-modal/map-modal.module').then( m => m.MapModalPageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  },
  {
    path: 'signup-activation',
    loadChildren: () => import('./signup-activation/signup-activation.module').then( m => m.SignupActivationPageModule)
  },
  {
    path: 'poi/:placeUname/:poiUname',
    loadChildren: () => import('./poi/poi.module').then( m => m.PoiPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
