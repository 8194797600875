import { Injectable } from '@angular/core';
import { StoreService } from '../services/store.service';
import { ApiService } from '../services/api.service';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { BEditaClientResponse } from '@atlasconsulting/bedita-sdk';
import { LoginModalPage } from '../login-modal/login-modal.page';
import { PayModalPage } from '../pay-modal/pay-modal.page';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    /**
     * Observable for user exposed to the world.
     */
    public user$: Observable<any>;

    /**
     * Behavior subject for user.
     */
    private user: BehaviorSubject<any> = new BehaviorSubject(false);

    private isLoginModalOpen = false;
    private isPayModalOpen = false;

    constructor(
        private store: StoreService,
        private apiService: ApiService,
        public modalController: ModalController,
        public platform: Platform,
    ) {
        // emit only when user changes
        this.user$ = this.user.asObservable()
            .pipe(
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
            );

        this.init();
    }

    public async init() {
        const user = await this.getUser();
        if (user) {
            this.user.next(user);
        }
    }

    public async getUser() {
        return await this.store.get('user');
    }

    public async login(username: string, password: string): Promise<BEditaClientResponse> {
        await this.apiService.getClient().authenticate(username, password);
        const response = await this.apiService.getClient().getUserAuth();
        const user = response.formattedData;
        this.user.next(user);
        await this.store.set('user', user);

        return user;
    }

    public async logout() {
        this.apiService.getClient().getStorageService().clearTokens();
        await this.store.remove('user');
        this.user.next(null);
    }

    public async showLoginPage() {
        if (this.isLoginModalOpen) {
            return;
        }

        const modal: HTMLIonModalElement = await this.modalController.create({
            component: LoginModalPage,
            componentProps: { },
            cssClass: 'login-modal',
            animated: !this.platform.is('desktop'),
        });
        modal.onDidDismiss().then(() => {
            this.isLoginModalOpen = false;
        });

        modal.present();
        this.isLoginModalOpen = true;
    }

    public async showPayPage(place?: any, user?: any) {
        if (this.isPayModalOpen) {
            return;
        }

        const modal: HTMLIonModalElement = await this.modalController.create({
            component: PayModalPage,
            componentProps: { place, user },
            cssClass: 'pay-modal',
            animated: !this.platform.is('desktop'),
        });
        modal.onDidDismiss().then(() => {
            this.isPayModalOpen = false;
        });

        modal.present();
        this.isPayModalOpen = true;
    }
}
