import { NgModule,Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ImageModalPageRoutingModule } from './image-modal-routing.module';

import { ImageModalPage } from './image-modal.page';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ImageModalPageRoutingModule,
    TranslateModule
  ],
  declarations: [ImageModalPage]
})
export class ImageModalPageModule {
    @Input() url = '';
}
