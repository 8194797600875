import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {

    @Input() currentLanguage: string;
    @Input() user: any = null;
    @Output() languageToggle: EventEmitter<any> = new EventEmitter<any>();

    public toggleLanguageLabel: string;

    constructor(
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.toggleLanguageLabel = (this.currentLanguage === 'it')?  'en' : 'it';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentLanguage) {
            this.toggleLanguageLabel = (changes.currentLanguage.currentValue === 'it')?  'en' : 'it';
        }
    }

    askLogin() {
        this.userService.showLoginPage();
    }

    logout() {
        this.userService.logout();
        this.router.navigate(['/home']);
    }
}
