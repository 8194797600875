/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ApiProvider, BEditaApiClient, BEditaClientResponse } from '@atlasconsulting/bedita-sdk';
import { StoreService } from '../services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { API_KEY, API_URL, APP_URL } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private client: BEditaApiClient;

  constructor(
      private store: StoreService,
      private translate: TranslateService,
  ) {
    this.client =  ApiProvider.get('bedita', {
        baseUrl: API_URL,
        apiKey: API_KEY,
    });
  }

  public async inidData(lang = 'it'): Promise<BEditaClientResponse> {
    const response = await this.client.get(`/navigatour?lang=${lang}`);
    if (response?.data) {
      await this.store.set('places', response.data);
    }

    return response;
  }

  public getClient() {
    return this.client;
  }

  public async signup(email: string, password: string): Promise<BEditaClientResponse> {
    const username = email;
    const payload = {
        username,
        email,
        password,
        activation_url: `${APP_URL}/signup-activation`,
        roles: ['visitor']
    };

    return await this.client.post('/signup', payload);
  }

  public async signupActivation(uuid: string) {
    const payload = { uuid };

    return await this.client.post('/signup/activation', payload);
  }

  public async verifyEmail(email: string): Promise<BEditaClientResponse> {
    const payload = {
        contact: email,
        change_url: `${APP_URL}/reset-password`,
    };

    return await this.client.post('/auth/change', payload);
  }

  public async changePassword(uuid: string, password: string): Promise<BEditaClientResponse> {
    const payload = {
        uuid,
        password,
        login: true
    };

    return await this.client.patch('/auth/change', payload);
  }

  public async getPlaces() {
    const places = await this.store.get('places');
    if (places) {
      return places;
    }

    const response = await this.inidData(this.translate.currentLang ?? 'it');
    return response?.data || null;
  }

  public async getPlace(uname: string) {
    const places = await this.getPlaces();
    if (places) {
      const place = places.find((p: { uname: any }) => p.uname === uname);
      return place || {};
    }

    return null;
  }

  public async pay(email: string, location: string, successUrl: string, cancelUrl: string) {
    const payload = {
      email,
      location,
      successUrl,
      cancelUrl
    };

    return await this.client.post('/start-checkout', payload);
  }

    public async validatePayment(locationUname: string, code: string): Promise<BEditaClientResponse> {
        try {
            const response = await this.client.post('/validate-code', { location: locationUname, code });

            if (!response.data.expiry) {
                throw new Error('system_error');
            }

            const expireTS = new Date(response.data.expiry).setHours(24, 0, 0, 0); // grant the last day till midnight
            if (expireTS < Date.now()) {
                throw new Error('code_expired');
            }

            return response;

        } catch (error) {
            if (error?.response?.status) {
                if (error.response.status < 500) {
                    error = new Error('code_invalid');
                } else {
                    error = new Error('system_error');
                }
            }
            return Promise.reject(error);
        }
    }

    public async validatePaymentForLocation(locationUname: string, code?: string) {
        const key = `purchased_items.${locationUname}`;
        if (!code) {
            const result = await this.store.get(key);
            if (!result?.code) {
                return false;
            }
            code = result.code;
        }

        // this throws exception, on invalid payment data
        await this.validatePayment(locationUname, code);

        return true;
    }
}
