import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { toastController } from '@ionic/core';
import { StoreService } from './services/store.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

    public currentUrl: string;
    public user: any = null;

    constructor(
        private router: Router,
        private store: StoreService,
        private userService: UserService,
        private modalController: ModalController,
        public translate: TranslateService,
    ) {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.currentUrl = val.url;
            }
        });
    }

    async ngOnInit() {
        await this.initLanguage();

        this.userService.user$.subscribe(user => {
            this.user = user;
        });

        // close modals on navigate (i.e. the browser back button)
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.modalController.getTop().then( modal => modal && modal.dismiss() );
            }
        });

    }

    ngAfterViewInit() {
        document.getElementById('initial-spinner-container').remove();
        if (!window.localStorage.getItem('acceptedCookies')) {
            this.presentCookieMessage();
        }
    }

    async presentCookieMessage() {
        const toast = await toastController.create({
            position: 'bottom',
            color: 'primary',
            message: 'Questa applicazione utilizza cookie di terze parti per presentare i contenuti video e le mappe',
            buttons: [
            {
                text: 'OK',
                side: 'end',
                handler: () => {
                    window.localStorage.setItem('acceptedCookies', 'true');
                    return Promise.resolve(true);
                },
            }],
        });

        await toast.present();
    }

    onLanguageToggle() {
        const lang = (this.translate.currentLang === 'it')?  'en' : 'it';
        this.translateLanguageTo(lang);
    }

    private translateLanguageTo(lang: string) {
        this.translate.use(lang);
        this.store.set('lang', lang);
        document.querySelector('html').setAttribute('lang', lang);
        // FORSE QUESTO È SUPERFLUO - TODO
        this.translate.currentLang = lang;
    }

    private async initLanguage(): Promise<void> {
        this.translate.addLangs(['it', 'en']);
        let userLanguage = await this.store.get('lang');
        if (!userLanguage) {
            userLanguage = navigator.language.startsWith('it')? 'it' : 'en';
        }

        this.translate.setDefaultLang(userLanguage);
        this.translateLanguageTo(userLanguage);
        return Promise.resolve();
    }
}
