import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private storage: Storage,
  ) {
    this.init();
  }

  public async init() {
    await this.storage.create();
  }

  public async get(key: string): Promise<any> {
    return await this.storage.get(key);
  }

  public async set(key: string, value: any): Promise<any> {
    return await this.storage.set(key, value);
  }

  public async remove(key: string): Promise<any> {
    return await this.storage.remove(key);
  }

  public getStorage(): Storage {
    return this.storage;
  }

  public async setPurchase(code: string, location: string, purchase: string, expiry: string) {
    const val = {
      code,
      location,
      purchase,
      expiry
    };
    const key = `purchased_items.${location}`;

    await this.storage.set(key, val);
  }

}
