import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    /**
     * Behavior subject for loading
     */
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Observable for loading information.
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public isLoading$: Observable<boolean> = this.loading.asObservable();

    /**
     * Keep the loading element
     */
    private loadingElement: HTMLIonLoadingElement;

    constructor(private loadingController: LoadingController) { }

    /**
     * Show loader
     *
     * @param options Options for loader
     */
    async show(options: {[s: string]: any} = {}): Promise<any> {
        options = {
            ...{
                cssClass: 'page-spinner',
            },
            ...options
        };
        if (this.loading.value === true) {
            return this.loadingElement;
        }

        this.loading.next(true);
        this.loadingElement = await this.loadingController.create(options);
        this.loadingElement.present();

        this.loadingElement.onDidDismiss()
            .then(() => this.loading.next(false));

        return this.loadingElement;
    }

    /**
     * Hide loader
     */
    async hide(): Promise<any> {
        if (this.loading.value === false) {
            return;
        }

        if (this.loadingElement) {
            return await this.loadingElement.dismiss();
        }

        return await this.loadingController.dismiss();
    }
}
