import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.page.html',
    styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {

    url: string = '';
    i: number;
    placeId: number;

    sliderOptions = {
        zoom: {
            maxRatio: 3,
            toggle: true,
        }
    };

    constructor(
        private navParamas: NavParams,
        private modalController: ModalController,
    ) { }

    ngOnInit() {
        this.i = this.navParamas.get('i');
        this.placeId = this.navParamas.get('placeId');
    }

    close() {
        this.modalController.dismiss();
    }
}
