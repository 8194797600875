import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { LoaderService } from '../services/loader.service';
import { format, parseISO, sub } from 'date-fns';
import { enGB, it } from 'date-fns/locale';

@Component({
    selector: 'app-pay-modal',
    templateUrl: './pay-modal.page.html',
    styleUrls: ['./pay-modal.page.scss'],
})
export class PayModalPage implements OnInit {

    public place: any;
    public user: any;
    public email: string;
    public purchaseCode: string;
    public lastDate = '';

    constructor(
        private modalController: ModalController,
        private apiService: ApiService,
        private loaderService: LoaderService,
        private toastController: ToastController,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.email = this.user?.data?.attributes?.username || '';
        if (this.place.expiryDate) {
            const day = sub(parseISO(this.place.expiryDate), { days: 1 });
            this.lastDate = format(day, 'EEEE d MMMM yyyy', {
                locale: (this.translate.currentLang === 'it')? it : enGB
            });
        }
    }

    async purchase() {
        try {
            const successUrl = location.protocol + '//' + location.host + '/checkout/success';
            const cancelUrl = location.href.replace(location.search, '');

            await this.loaderService.show();
            const response = await this.apiService.pay(this.email, this.place.uname, successUrl, cancelUrl);
            await this.loaderService.hide();

            if (response.data?.url) {
                window.location = response.data.url;
            }
        } catch (e) {
            await this.loaderService.hide();
            const toast = await this.toastController.create({
                message: this.translate.instant('PLACE_PAGE.PURCHASE_FAILURE'),
                color: 'danger',
                buttons: [
                    {
                        text: this.translate.instant('GENERAL.CLOSE'),
                        role: 'cancel',
                        handler: () => { }
                    },
                ]
            });
            toast.present();
        }
    }

    async verifyCode() {
        try {
            await this.loaderService.show();
            await this.apiService.validatePayment(this.place.uname, this.purchaseCode);

            // await this.store.setPurchase(this.purchaseCode, this.place.uname, '', response.data.expiry);
            await this.loaderService.hide();
        } catch (e) {
            await this.loaderService.hide();
            const toast = await this.toastController.create({
                message: this.translate.instant('PLACE_PAGE.VERIFY_CODE_FAILURE'),
                color: 'danger',
                buttons: [
                    {
                        text: this.translate.instant('GENERAL.CLOSE'),
                        role: 'cancel',
                        handler: () => { }
                    },
                ]
            });
            toast.present();
        }
    }

    close() {
        this.modalController.dismiss();
    }
}
