import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ModalController, ToastController, IonInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader.service';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.page.html',
    styleUrls: ['./login-modal.page.scss'],
})
export class LoginModalPage {

    @ViewChild('loginForm') loginForm: FormGroup;
    @ViewChild('loginInputPassword') loginInputPassword: IonInput;
    @ViewChild('signupInputPassword') signupInputPassword: IonInput;
    @ViewChild('signupForm') signupForm: FormGroup;

    public loginData = {
        username: '',
        password: '',
    };

    public signupData = {
        username: '',
        password: '',
        confirmPassword: '',
    };

    constructor(
        private router: Router,
        private apiService: ApiService,
        private userService: UserService,
        private loaderService: LoaderService,
        private modalController: ModalController,
        private toastController: ToastController,
        private translate: TranslateService,
    ) { }

    loginFocusNext() {
        this.loginInputPassword.setFocus();
    }

    loginOnEnter() {
        if (this.loginForm.valid) {
            this.login();
        }
    }

    signupFocusNext() {
        this.signupInputPassword.setFocus();
    }

    signupOnEnter() {
        if (this.signupForm.valid) {
            this.signup();
        }
    }

    public async login() {
        try {
            await this.loaderService.show();
            await this.userService.login(this.loginData.username, this.loginData.password);
            await this.loaderService.hide();
            this.close();
            if (this.router.url.includes('/signup-activation')) {
                this.router.navigate(['/home']);
            }
        } catch (e) {
            await this.loaderService.hide();
            const toast = await this.toastController.create({
                message: this.translate.instant('LOGIN.FAILURE'),
                color: 'danger',
                buttons: [
                    {
                        text: 'Close',
                        role: 'cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                        }
                    },
                ]
            });
            toast.present();
        }
    }

    public async signup() {
        try {
            await this.loaderService.show();
            await this.apiService.signup(this.signupData.username, this.signupData.password);
            await this.loaderService.hide();

            const toast = await this.toastController.create({
                message: this.translate.instant('LOGIN.SIGNUP_SUCCESS'),
                buttons: [
                    {
                        text: this.translate.instant('LOGIN.OK'),
                        role: 'cancel',
                        handler: () => {
                            // this.router.navigate(['/home']);
                        }
                    },
                ]
            });
            toast.present();

            return;
        } catch (e) {
            await this.loaderService.hide();

            let message = this.translate.instant('LOGIN.SIGNUP_FAIL');
            if (e.response?.status === 400 && e.response?.data?.error?.code === 'be_user_exists') {
                message = this.translate.instant('LOGIN.USER_EXISTS');
            }

            const toast = await this.toastController.create({
                message,
                color: 'danger',
                buttons: [
                    {
                        text: this.translate.instant('GENERAL.CLOSE'),
                        role: 'cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                        }
                    },
                ]
            });
            toast.present();

            return e.response;
        }
    }

    close() {
        this.modalController.dismiss();
    }
}
